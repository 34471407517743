import { ToastProvider, useIsBreakpointLargeAndUp } from '@afterpaytouch/core'
import React, { FunctionComponent, ReactNode } from 'react'

import { useConsumerCountry } from '../../state'
import { Header } from '../../component/Header'
import clsx from 'clsx'
import styles from './style.module.css'

interface Props {
  children?: ReactNode
  isHideClose?: boolean
  fullscreenWhite?: boolean
}

export const WhiteBgLayout: FunctionComponent<Props> = ({ children, isHideClose, fullscreenWhite }) => {
  const country = useConsumerCountry()
  const isDesktop = useIsBreakpointLargeAndUp()
  const classNames = clsx(styles.container, isDesktop && styles.desktop)
  return (
    <div className={fullscreenWhite ? clsx(styles.light, 'bg-light') : styles.original}>
      <div className={classNames}>
        <ToastProvider>
          <Header country={country} isHideClose={isHideClose} />
          {children}
        </ToastProvider>
      </div>
    </div>
  )
}

// eslint-disable-next-line react/display-name
export const getLayout = (props?: Props) => (page) => <WhiteBgLayout {...props}>{page}</WhiteBgLayout>
