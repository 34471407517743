import { Divider, Icon, Logo, useIsCashTheme, Text } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent } from 'react'
import { Country } from '@afterpaytouch/portal-api/types'
import styles from './style.module.css'
import { Route } from '../../config/router'
import { useRouter } from 'next/router'

export interface HeaderProps {
  country?: Country
  testNameSpace?: string
  isHideClose?: boolean
}

const I18N_NAMESPACE = ['common']

export const Header: FunctionComponent<HeaderProps> = ({ country, testNameSpace = '', isHideClose }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const route = useRouter()
  const isCashTheme = useIsCashTheme()

  // Do not show the tagline by default and do not show in consumer lending enabled countries
  const showTagline = typeof country === 'undefined' || isCashTheme ? false : ![Country.US].includes(country)
  return (
    <header>
      <div className={styles.header} {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-header` })}>
        <div className={styles.headerBody}>
          <div className={styles.standardHeader}>
            <div className={styles.headerLogoRow}>
              <Logo name={t('common:brandName')} title={t('common:brandName')} description={t('common:brandName')} size={showTagline ? 'Small' : 'Medium'} />
            </div>
            {showTagline && (
              <div className={styles.taglineWrapper}>
                <Text size='M' testNameSpace='tagline'>
                  {t('common:tagline')}
                </Text>
              </div>
            )}
          </div>
        </div>
        {!isHideClose && (
          <div
            className={styles.closeWrapper}
            onClick={() => {
              route.push(Route.INDEX)
            }}
          >
            <Icon.Close size='Small' />
          </div>
        )}
      </div>
      <Divider kind='Hairline' />
    </header>
  )
}
