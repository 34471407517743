import React, { useEffect } from 'react'
import { ConsumerPage } from '../../model'
import { Trans, useTranslation } from 'next-i18next'
import { Head } from '../../component'
import { TrackPageViewed, useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { Heading, Link, Loader, Text } from '@afterpaytouch/core'
import { useRouter } from 'next/router'
import { useConsumerSizes } from '../../utils/responsive'
import { LoginIdentity } from '../../component/LoginIdentity'
import { useIsConsumerAuthenticated } from '../../state'
import { useConsumerFeatureFlagReady, useLazyGetAccountQuery } from '../../state/account/account.hooks'
import { getPortalMigrationEnabled } from '../../utils/convergence'
import { Route } from '../../config/router'
import { TrackingEvent } from '../../model/amplitude'
import { getCountryCodeFromLocale } from '../../utils/consumer'

const I18N_NAMESPACE = ['common', 'login']

export const IndexPage: ConsumerPage = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const pageTitle = `${t('login:title')} | ${t('common:brandName')}`
  const router = useRouter()
  const { headingSize } = useConsumerSizes()
  const hasFeatureFlagsReady = useConsumerFeatureFlagReady()
  const { locale } = router
  const extractedCountryCodeFromLocale = getCountryCodeFromLocale(locale)
  const loginMigrationEnabled = getPortalMigrationEnabled(extractedCountryCodeFromLocale)
  const isConsumerAuthenticated = useIsConsumerAuthenticated()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const [triggerAccount, { isSuccess: isAccountSuccess, isLoading }] = useLazyGetAccountQuery()
  const showLoading = !hasFeatureFlagsReady || !loginMigrationEnabled || isLoading

  useEffect(() => {
    router.prefetch(Route.HOME)
    router.prefetch(Route.LOGIN_PASSWORD)
  }, [])

  useEffect(() => {
    if (isAccountSuccess || isConsumerAuthenticated) {
      router.push(Route.HOME)
    }
  }, [isAccountSuccess, isConsumerAuthenticated])

  useEffect(() => {
    if (hasFeatureFlagsReady && typeof loginMigrationEnabled !== 'undefined') {
      // TODO: clean code once rollout finished
      if (!loginMigrationEnabled) {
        router.push(Route.HOME)
      } else {
        triggerAccount()
      }
    }
  }, [loginMigrationEnabled, hasFeatureFlagsReady, router, isAccountSuccess, triggerAccount])

  /* TODO clean code once rollout finished */
  return showLoading ? (
    <>
      <Head title={pageTitle} />
      <Loader alignCenter />
      <TrackPageViewed eventProps={{ loginMigrationEnabled, loading: true }} />
    </>
  ) : (
    <>
      <Head title={pageTitle} />
      <Heading size={headingSize}>{t('login:title')}</Heading>
      <LoginIdentity />
      <div className='mt-2 text-center'>
        <Text size='XS'>
          <Trans
            i18nKey={t('login:download')}
            components={{
              DownloadLink: (
                <Link
                  inline
                  kind='Underline'
                  href={t('common:urls.mobilePageBranchLink')}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => logEvent(TrackingEvent.CLICK_DOWNLOAD_MOBILE_APP)}
                />
              ),
            }}
          />
        </Text>
      </div>
      <div className='mt-6 text-center'>
        <Link
          kind='Underline'
          href={t('common:urls.merchantPortalLoginUrl')}
          onClick={() => {
            logEvent(TrackingEvent.CLICKED_RETAILER_LINK)
          }}
        >
          {t('login:retailer')}
        </Link>
      </div>
      <TrackPageViewed eventProps={{ loginMigrationEnabled, loading: false }} />
    </>
  )
}
