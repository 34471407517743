import React from 'react'
import { GetServerSideProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { getTheme } from '../src/utils/server/convergence'
import { getLayout } from '../src/layouts/WhiteBgLayout/WhiteBgLayout'
import { ConsumerPage } from '../src/model'
import { IndexPage as IndexEntryPage } from '../src/page/IndexPage/IndexPage'

const I18N_NAMESPACE = ['common', 'login']

const IndexPage: ConsumerPage = (props) => <IndexEntryPage {...props} />

IndexPage.getLayout = getLayout({ isHideClose: true, fullscreenWhite: true })

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { theme } = await getTheme(context)
  return {
    props: {
      theme,
      ...(await serverSideTranslations(context.locale as string, I18N_NAMESPACE)),
    },
  }
}

export default IndexPage
