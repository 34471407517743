import { Button, CallbackExtraFieldsType, Grid, Heading, InputEmailPhone, InputOnChange, useIsCashTheme } from '@afterpaytouch/core'
import React, { FormEvent, FunctionComponent, useState } from 'react'
import { isValidEmail } from '../../utils/validation'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useConsumerSizes } from '../../utils/responsive'
import { useEmailLookupMutation } from '../../state/account'
import { isUserLookupResponseType } from '@afterpaytouch/portal-api/consumer/lookup'
import { Route } from '../../config/router'
import { ErrorCode, isPortalApiError } from '@afterpaytouch/portal-api'
import { getPortalUrlViaCountry } from '../../utils/redirects'
import { clearCookie } from '../../state/actions'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { getCountryCodeFromLocale } from '../../utils/consumer'
import { updateConsumerEmail } from '../../state/account/consumer/reducer'
import { useAppDispatch } from '../../state'

export const LoginIdentityErrors: Partial<Record<ErrorCode, string>> = {
  [ErrorCode.InvalidEmailAddress]: 'emailNotValid',
  [ErrorCode.RegistrationNotPermitted]: 'registrationNotPermitted',
}

export const LoginIdentity: FunctionComponent = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { locale } = router
  const extractedCountryCodeFromLocale = getCountryCodeFromLocale(locale)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [lock, setLocked] = useState(false)
  const { textSize } = useConsumerSizes()
  const testNameSpace = 'login-identity'
  const [userIdentityValue, setUserIdentityValue] = useState('')
  const [emailLookup, { isLoading }] = useEmailLookupMutation()
  const isCashTheme = useIsCashTheme()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const [hasLoggedTypeEmailEvent, setHasLoggedTypeEmailEvent] = useState(false)
  const dispatch = useAppDispatch()

  const onEmailChange = (e: InputOnChange, { rawValue = '' }: CallbackExtraFieldsType): void => {
    const value: string = e.target.value ?? ''
    if (value !== '') {
      setErrorText('')
      setError(false)
      if (!hasLoggedTypeEmailEvent) {
        logEvent(TrackingEvent.TYPED_EMAIL_ON_LOGIN_PAGE)
        setHasLoggedTypeEmailEvent(true)
      }
    }
    setUserIdentityValue(rawValue)
  }

  const validateForm = (): boolean => {
    if (isValidEmail(userIdentityValue)) {
      return true
    }
    if (userIdentityValue === '') {
      setErrorText(t('login:error:emailOrMobileIsRequired'))
    } else {
      setErrorText(t('login:error:emailNotValid'))
    }
    return false
  }
  const handleEmailLookupError = (code: ErrorCode): void => {
    setErrorText(t(`login:error:${LoginIdentityErrors[code] ?? 'unknown'}`))
  }

  const doEmailLookup = async (): Promise<void> => {
    try {
      const result = await emailLookup({
        email: userIdentityValue,
        isFromRegistration: false,
      }).unwrap()
      const isUserLookupResponse = isUserLookupResponseType(result)
      logEvent(TrackingEvent.USER_LOOK_UP_ON_LOGIN_PAGE, {
        success: !('error' in result),
        existing: result?.existing ?? undefined,
        passwordExists: result?.passwordExists ?? undefined,
      })
      if (isUserLookupResponse) {
        const existing = result.existing
        if (existing) {
          const countryCode = result.countryCode
          const passwordExists = result.passwordExists
          if (countryCode !== extractedCountryCodeFromLocale) {
            logEvent(TrackingEvent.CROSS_REGION_USER_REDIRECT_ON_LOGIN_PAGE, {
              from: extractedCountryCodeFromLocale,
              to: countryCode,
            })
            clearCookie()
            window.location.href = getPortalUrlViaCountry(countryCode)
            return
          }
          // Explicitly set email in Redux state before navigation
          dispatch(updateConsumerEmail(userIdentityValue))
          if (passwordExists) {
            await router.push(Route.LOGIN_PASSWORD)
          } else {
            await router.push(Route.CREATE_PASSWORD_VERIFY)
          }
        } else {
          setErrorText(t('login:error:accountNotFound'))
        }
      }
    } catch (e) {
      const code = isPortalApiError(e.data) ? e.data.errorCode : ErrorCode.Error
      logEvent(TrackingEvent.USER_LOOK_UP_ON_LOGIN_PAGE, {
        success: false,
        error: e,
        errorCode: code,
      })
      handleEmailLookupError(code)
    }
  }

  const onFormSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()
    const isValid = validateForm()
    logEvent(TrackingEvent.PRESSED_CONTINUE_ON_LOGIN_PAGE, { isValid })

    if (!isValid) {
      return
    }
    setLocked(true)
    await doEmailLookup()
    setLocked(false)
  }

  const onEmailBlur = (e: React.FocusEvent<HTMLInputElement>, { rawValue = '' }: CallbackExtraFieldsType): void => {
    setUserIdentityValue(rawValue)
  }

  return (
    <>
      <form onSubmit={onFormSubmit} className='mt-4' noValidate={true} {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-form` })}>
        {!isCashTheme && (
          <Grid>
            <Heading size={textSize} id='loginEmailLabel'>
              {t('login:label')}
            </Heading>
          </Grid>
        )}
        <Grid>
          <MaskedElement stylingModifier='wrap' actionName='Mobile/Email Input'>
            <InputEmailPhone
              enableInputPhoneMode={false}
              label={t('login:label')}
              onChange={onEmailChange}
              onBlur={onEmailBlur}
              name='identifier'
              testNameSpace={testNameSpace}
              required={true}
              autoComplete='username'
              error={error || Boolean(errorText)}
              errorText={errorText}
              disabled={lock || isLoading}
              aria-labelledby='loginEmailLabel'
              autoFocus={true}
              autoSelectOnFocus={true}
            />
          </MaskedElement>
        </Grid>
        <Grid>
          <Grid.Item>
            <Button.Primary loading={lock} type='submit' testNameSpace={testNameSpace} disabled={lock} padding='Fluid'>
              {t('common:continue')}
            </Button.Primary>
          </Grid.Item>
        </Grid>
      </form>
    </>
  )
}
